import { Group, Layer } from '@pixi/layers';
import { Loader, Sprite } from 'pixi.js';
import { Spine } from 'pixi-spine';

import { MAPPED_SYMBOLS_LAND_ANIMATIONS, SlotId } from '../../config';
import { Cascade, EventTypes, GameMode } from '../../global.d';
import {
  setCurrentIsTurboSpin,
  setIsTurboSpin,
  setSlotConfig,
} from '../../gql/cache';
import { Logic } from '../../logic';
import {
  cascadeEase,
  getCascadeColumns,
  getSlotOrderBySlotId,
  isRegularMode,
  nextTick,
} from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { CallbackPriority, TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BASE_APPEARING_DURATION,
  DELAY_BETWEEN_REELS,
  eventManager,
  FORCE_STOP_CASCADE_ANIMATION_DURATION,
  FORCE_STOP_CASCADE_PER_EACH_DURATION,
  REEL_WIDTH,
  REELS_AMOUNT,
  ReelState,
  RESET_ANIMATION_BASE_DURATION,
  RESET_ANIMATION_TURBO_DURATION,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_APPEARING_DURATION,
} from '../config';
import { Icon } from '../d';
import Reel from './reel';
import Slot from './slot';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public landedReels: number[] = [];

  private landingContainer: ViewContainer = new ViewContainer();

  private isSoundPlayed = false;

  private isForceStopped = false;

  private layer: Layer;

  private slotGroup: Group;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.slotGroup = new Group(1, (slot) => {
      slot.zOrder = getSlotOrderBySlotId((slot as Slot).slotId);
    });
    this.landingContainer.sortableChildren = true;
    this.layer = new Layer(this.slotGroup);
    this.initReels(reels, startPosition, this.slotGroup);
    this.addChild(this.layer);
    this.addChild(this.landingContainer);
    eventManager.addListener(
      EventTypes.SET_SLOTS_VISIBILITY,
      this.setSlotsVisibility.bind(this),
    );
    eventManager.addListener(
      EventTypes.SETUP_REEL_POSITIONS,
      this.setupAnimationTarget.bind(this),
    );
    eventManager.addListener(
      EventTypes.FORCE_STOP_REELS,
      this.forceStopReels.bind(this),
    );
    eventManager.addListener(
      EventTypes.ROLLBACK_REELS,
      this.rollbackReels.bind(this),
    );
    eventManager.addListener(
      EventTypes.REEL_LANDED,
      this.checkLandedReels.bind(this),
    );
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.landedReels = [];
      this.isForceStopped = false;
    });
    eventManager.addListener(
      EventTypes.START_RANDOM_WILDS_ANIMATION,
      this.createReplaceRandomWildsAnimation.bind(this),
    );
    this.sortableChildren = true;
  }

  private checkLandedReels(id: number): void {
    this.landedReels.push(id);
    if (this.landedReels.length === REELS_AMOUNT) {
      this.landedReels = [];
      eventManager.emit(EventTypes.REELS_STOPPED);
    }
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        spinResult.push(
          setSlotConfig().icons.find(
            (icon) =>
              icon.id ===
              this.reels[i].slots.find(
                (slot) => slot.id === SLOTS_PER_REEL_AMOUNT - j - 1,
              )!.slotId,
          )!,
        );
      }
    }
    return spinResult;
  }

  public createResetReelsAnimation(winPositions: number[][]): Animation {
    const cascade = winPositions
      .reduce((res, current) => {
        return [...res, ...current];
      }, [])
      .filter((v, i, a) => a.indexOf(v) === i);
    const sortedCascade = cascade.sort();
    sortedCascade.forEach((elem) => {
      const reel = this.reels[elem % REELS_AMOUNT];
      const index = reel.slots.findIndex(
        (slot) => slot.id === 3 - Math.floor(elem / REELS_AMOUNT),
      );
      reel.slots.splice(index, 1);
    });
    this.reels.forEach((reel) => {
      reel.slots.forEach((slot, index) => {
        if (slot.id !== reel.slots.length - index - 1)
          slot.id = reel.slots.length - index - 1;
      });
    });
    const isTurboSpin =
      setCurrentIsTurboSpin() && isRegularMode(Logic.the.controller.gameMode);
    const animation = new AnimationGroup();
    this.reels.forEach((reel, reelIndex) => {
      const chain = new AnimationChain();
      chain.appendAnimation(Tween.createDelayAnimation(reelIndex * 20));
      const group = new AnimationGroup();
      reel.slots.forEach((slot) => {
        const propertyBeginValue = slot.y;
        const target = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
        group.addAnimation(
          new Tween({
            object: slot,
            property: TweenProperties.Y,
            propertyBeginValue,
            target,
            duration: isTurboSpin
              ? RESET_ANIMATION_TURBO_DURATION
              : RESET_ANIMATION_BASE_DURATION,
          }),
        );
      });
      chain.appendAnimation(group);
      animation.addAnimation(chain);
    });
    return animation;
  }

  private rollbackReels(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i].cascadeAnimation?.getDisappearing().end();
      this.reels[i].cascadeAnimation?.getWaiting().end();
      this.reels[i].slots.forEach((slot, id) => {
        slot.y = (id + 0.5) * SLOT_HEIGHT;
      });
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private initReels(
    reels: SlotId[][],
    startPosition: number[],
    slotGroup: Group,
  ): void {
    const cascades = getCascadeColumns({
      reelPositions: startPosition,
      layout: reels,
      cascades: [],
    });
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i], position, cascades[i], slotGroup);
      this.reels[i] = reel;
      this.addChild(reel.container);
    }
  }

  private forceStopReels(): void {
    this.isForceStopped = true;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const appearingAnimations = this.reels[
        i
      ].cascadeAnimation!.getAppearingAnimations();
      const delayAnim = this.reels[i].cascadeAnimation!.getAppearingDelays();
      delayAnim.duration = FORCE_STOP_CASCADE_PER_EACH_DURATION;
      appearingAnimations.forEach((animation, index) => {
        animation.duration = FORCE_STOP_CASCADE_ANIMATION_DURATION;
      });
    }
  }

  private setupAnimationTarget(
    layout: Array<Array<Icon>>,
    isStopped: boolean,
  ): void {
    const isTurboSpin = setCurrentIsTurboSpin() && isRegularMode(Logic.the.controller.gameMode);
    this.isSoundPlayed = false;
    for (let j = 0; j < this.reels.length; j++) {
      const reel = this.reels[j];
      const appearingChain = new AnimationChain();
      if (!isTurboSpin && !isStopped) {
        appearingChain.appendAnimation(
          Tween.createDelayAnimation(j * DELAY_BETWEEN_REELS),
        );
      }
      const appearingAnimation = new AnimationGroup();
      appearingChain.appendAnimation(appearingAnimation);
      reel.cascadeAnimation?.appendAnimation(appearingChain);
      const waitingAnimation = reel.cascadeAnimation!.getWaiting();
      waitingAnimation.addOnComplete(() => {
        const column = layout[j];
        reel.createSlots(
          column.map((icon) => icon.id),
          this.slotGroup,
        );
        for (let i = 0; i < reel.slots.length; i++) {
          const slot = reel.slots[reel.slots.length - i - 1];
          const target = (SLOTS_PER_REEL_AMOUNT - i - 0.5) * SLOT_HEIGHT;
          const propertyBeginValue =
            (SLOTS_PER_REEL_AMOUNT - i - 5) * SLOT_HEIGHT;
          slot.y = propertyBeginValue;
          const baseDuration = isTurboSpin
            ? TURBO_APPEARING_DURATION
            : BASE_APPEARING_DURATION;
          const appearing = new Tween({
            object: slot,
            property: TweenProperties.Y,
            propertyBeginValue,
            target,
            duration: isStopped
              ? FORCE_STOP_CASCADE_ANIMATION_DURATION
              : baseDuration,
            easing: i < 4 ? cascadeEase : (n) => n,
          });
          appearing.addOnComplete(() => {
            if (slot.id < SLOTS_PER_REEL_AMOUNT) {
              slot.onSlotStopped();
              this.createLandAnimation(slot, j);
            }
          }, CallbackPriority.HIGH);
          appearingAnimation.addAnimation(appearing);
        }
        appearingAnimation.addOnStart(() => {
          reel.changeState(ReelState.APPEARING);
        });
        appearingAnimation.addOnComplete(() => {
          reel.changeState(ReelState.IDLE);
        });
      });

      waitingAnimation.end();
    }
  }

  private createReplaceRandomWildsAnimation(
    cascade: Cascade,
    nextCascadeId: number,
  ): void {
    eventManager.emit(EventTypes.START_GENERAL_RANDOM_WILDS);
    const positions = cascade.cascadeFall.reduce<number[]>((res, line, row) => {
      const linePositions = line.reduce<number[]>((res, slot, col) => {
        if (slot === SlotId.WL) return [...res, row * REELS_AMOUNT + col];
        return res;
      }, []);
      return [...res, ...linePositions];
    }, []);
    for (let i = 0; i < positions.length; i++) {
      const slot = this.reels[positions[i] % REELS_AMOUNT].slots.find(
        (slot) =>
          slot.id ===
          SLOTS_PER_REEL_AMOUNT - 1 - Math.floor(positions[i] / REELS_AMOUNT),
      );
      eventManager.emit(
        EventTypes.START_SINGLE_RANDOM_WILD,
        slot,
        i === positions.length - 1,
        nextCascadeId,
      );
    }
  }

  private createLandAnimation(slot: Slot, col: number): void {
    const src = MAPPED_SYMBOLS_LAND_ANIMATIONS[slot.slotId].src!;
    const landAnimation = new Spine(Loader.shared.resources[src].spineData!);
    landAnimation.zIndex = getSlotOrderBySlotId(slot.slotId);
    this.landingContainer.addChild(landAnimation);
    landAnimation.x = (col + 0.5) * REEL_WIDTH;
    landAnimation.y = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
    landAnimation.scale.set(SLOT_SCALE);
    slot.visible = false;

    landAnimation.state.addListener({
      complete: () => {
        nextTick(() => {
          if (slot.id === SLOTS_PER_REEL_AMOUNT - 1) {
            eventManager.emit(EventTypes.REEL_LANDED, col);
          }
          landAnimation.destroy();
          slot.visible = true;
        });
      },
    });
    landAnimation.state.setAnimation(
      0,
      MAPPED_SYMBOLS_LAND_ANIMATIONS[slot.slotId].animation!,
      false,
    );
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const slot = this.reels[x].slots.find((slot) => slot.id === 3 - y);
      this.reels[x].container.removeChild(slot as Sprite);
    });
  }
}

export default ReelsContainer;
