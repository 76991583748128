import AudioHowl from '@phoenix7dev/play-music';
import { Sprite, Text, Texture } from 'pixi.js';

import variables from '../../assets/styles/export.module.scss';
import { ISongs } from '../../config';
import {
  EventTypes,
  GameMode,
  ModalOpeningTypes,
  UserBonus,
} from '../../global.d';
import {
  setCurrentBonus,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isMobileDevice } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
  FEATURE_BTN_CONTAINER_POSITION_X,
  FEATURE_BTN_HEIGHT,
  FEATURE_BTN_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  PopupTypes,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyFeatureBtn extends ViewContainer {
  private btn: Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.x = -90;
    this.y = 300;
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      case GameMode.RAGE_MODE:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyFeatureBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyFeatureRageBtn));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (e) => {
      if (!this.isDisabled) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtnHover);

        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtn);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtnPressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtn);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(
      i18n.t<string>('buyFeatureBtn'),
      110,
      300,
      buyFeatureTextStyle,
    );

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins()
    ) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      AudioHowl.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(
        () => setIsModalOpeningInProgress(ModalOpeningTypes.NONE),
        100,
      );
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress || this.isFreeRoundBonus) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtnDisable);

      this.text.text.style = buyFeatureDisableTextStyle;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureRageBtn);

      this.text.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private get isFreeRoundBonus(): boolean {
    return this.isDisabled && setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
  }


  protected resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.resizedWidth < this.resizedHeight && isMobileDevice()) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH - 140 : 150;
      this.y = GAME_CONTAINER_HEIGHT + FEATURE_BTN_HEIGHT + 15;
      return;
    }
    if (
      this.resizedWidth >=
        parseInt(variables.breakpointTabletLandscapeMd, 10) &&
      this.resizedHeight <=
        parseInt(variables.breakpointTabletLandscapeXs, 10) &&
      isMobileDevice()
    ) {
      this.x = this.isLeftHandMode
        ? GAME_CONTAINER_WIDTH
        : FEATURE_BTN_CONTAINER_POSITION_X / 1.2;
    } else {
      this.x =
        this.isLeftHandMode && isMobileDevice()
          ? GAME_CONTAINER_WIDTH + 88
          : -90;
    }
    this.y = this.isLeftHandMode ? 300 : 300;
  }
}

export default BuyFeatureBtn;
