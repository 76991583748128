import { ISlotData } from '../slotMachine/d';

import { setCoinValue, setSlotConfig } from './cache';
import client from './client';
import { IBetSettings } from './d';
import { getBetSettingsGql } from './fragment';

export const getBetsSetting = (): IBetSettings => {
  const { id } = setSlotConfig();
  const { clientSettings, lineSets } = client.readFragment({
    id: `Slot:${id}`,
    fragment: getBetSettingsGql,
  });

  const minBet = clientSettings.coinAmounts.default[0] ?? 0;
  const maxBet = clientSettings.coinAmounts.default[clientSettings.coinAmounts.default.length - 1] ?? 0;
  const bets = clientSettings.coinAmounts.default;
  const { quick } = clientSettings.coinAmounts;
  const coinValue = setCoinValue();
  const { coinAmountMultiplier } = lineSets[0];
  const minBetCount = (minBet * coinAmountMultiplier * coinValue) / 100;
  const maxBetCount = (maxBet * coinAmountMultiplier * coinValue) / 100;

  return {
    minBet,
    maxBet,
    bets,
    quick,
    coinAmountMultiplier,
    coinValue,
    minBetCount,
    maxBetCount,
  };
};
