import { EventTypes } from '../../global.d';
import { setIsPopupOpened } from '../../gql/cache';
import { PopupTypes, eventManager } from '../config';
import { PopupProps } from '../d';

import { Popup } from './popup';

export class PopupController {
  public currentPopup: Popup | null = null;

  public popups: Map<PopupTypes, Popup>;

  public static the: PopupController = new PopupController();

  private constructor() {
    this.popups = new Map<PopupTypes, Popup>();
  }

  public registerPopup(type: PopupTypes, popup: Popup): void {
    this.popups.set(type, popup);
  }

  public openPopup(popupType: PopupTypes, props?: PopupProps): void {
    if (this.currentPopup) {
      this.currentPopup.hide();
      this.currentPopup = null;
    }
    setIsPopupOpened(true);
    eventManager.emit(EventTypes.OPEN_POPUP, popupType);
    // eventManager.emit(EventTypes.OPEN_POPUP_BG);
    this.currentPopup = this.popups.get(popupType) || null;
    this.popups.get(popupType)?.show(props);
  }

  public closeCurrentPopup(): void {
    if (this.currentPopup) {
      this.currentPopup.hide();
      this.currentPopup = null;
    }
    setIsPopupOpened(false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
    eventManager.emit(EventTypes.CLOSE_POPUP_BG);
  }
}
