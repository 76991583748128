import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { BuyFeatureEnterProps, EventTypes, GameMode, IBonus, UserBonus } from '../../global.d';
import {
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsBuyFeaturePurchased,
  setIsFreeSpinsWin,
  setIsPopupOpened,
} from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import { eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BuyFeatureController extends Controller {
  public gameMode = GameMode.BUY_FEATURE;

  public static the = new BuyFeatureController();

  private constructor() {
    super();
  }

  enterController(prevGameMode: GameMode, props: BuyFeatureEnterProps): void {
    if (prevGameMode !== GameMode.BASE_GAME) throw new Error('CANT BUY FEATURE NOT IN BASE MODE');
    client
      .mutate<{
        buyBonus: {
          bonus: UserBonus;
          id: string;
          balance: { amount: number; currency: string };
          data: {
            cascadeMultiplier: number;
          };
        };
      }>({
        mutation: buyBonusGql,
        variables: {
          input: {
            id: props?.bonusId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      })
      .then((res) => {
        const { bonus, balance, data } = res.data!.buyBonus;
        eventManager.emit(EventTypes.DISABLE_PAYTABLE);
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
        PopupController.the.closeCurrentPopup();
        setIsPopupOpened(false);
        setIsFreeSpinsWin(true);
        setFreeSpinsTotalWin(0);
        setIsBuyFeaturePurchased(false);
        setCurrentBonus({
          ...bonus,
          id: res.data!.buyBonus.id,
          isActive: true,
          currentRound: 0,
        });
        if (data.cascadeMultiplier === 10) {
          Logic.the.changeGameMode(GameMode.RAGE_MODE, {
            bonus,
          });
        } else {
          Logic.the.changeGameMode(GameMode.FREE_SPINS, {
            bonus,
          });
        }
      });
  }

  public exitController(nextGameMode: GameMode): void {
    AudioHowl.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioHowl.stop({ type: ISongs.BGM_BG_Melo_Loop });
  }
}
