import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class BrokenGame extends State {
  public name: States = States.BROKEN_GAME;

  public nodes: Map<States, State> = new Map();

  public static the = new BrokenGame();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterBrokenGameState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitBrokenGameState(nextState);
  }
}
