export enum ResourceTypes {
  buyButtonDefault = 'buyButtonDefault',
  buyButtonDisabled = 'buyButtonDisabled',
  buyButtonHover = 'buyButtonHover',
  buyButtonPressed = 'buyButtonPressed',
  buyFeatureConfirmPopupBackplate = 'buyFeatureConfirmPopupBackplate',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureRageBtnDisable = 'buyFeatureRageBtnDisable',
  buyFeatureRageBtnHover = 'buyFeatureRageBtnHover',
  buyFeatureRageBtnPressed = 'buyFeatureRageBtnPressed',
  buyFeatureRageBtn = 'buyFeatureRageBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  freeSpinsDefault = 'freeSpinsDefault',
  freeSpinsDisabled = 'freeSpinsDisabled',
  freeSpinsHover = 'freeSpinsHover',
  freeSpinsPressed = 'freeSpinsPressed',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeatureXBtnDisabled = 'buyFeatureXBtnDisabled',
  buyFeatureXBtnHover = 'buyFeatureXBtnHover',
  buyFeatureXBtnPressed = 'buyFeatureXBtnPressed',
  buyFeatureXBtn = 'buyFeatureXBtn',
  freeSpinsCounter = 'freeSpinsCounter',
  labelRageMode = 'labelRageMode',
  messageBanner = 'messageBanner',
  progressBarBase = 'progressBarBase',
  progressBarFreeSpins = 'progressBarFreeSpins',
  frameFreeSpins = 'frameFreeSpins',
  frameRageMode = 'frameRageMode',
  frame = 'frame',
  logo = 'logo',
  reelBackgroundFreeSpins = 'reelBackgroundFreeSpins',
  reelBackgroundRageMode = 'reelBackgroundRageMode',
  reelBackground = 'reelBackground',
  a = 'a',
  b = 'b',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  j = 'j',
  wL = 'wL',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  introBgMobile = 'introBgMobile',
  introBg = 'introBg',
  introLight = 'introLight',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  popupBg = 'popupBg',
  slotBgFreeSpins = 'slotBgFreeSpins',
  slotBgRageMode = 'slotBgRageMode',
  slotBg = 'slotBg',
}
