import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const EYES_AMOUNT = 5;
const EYES_SCALES = [1, 1.2, 1.3, 1.4, 1.5];
const EYES_POSITIONS = [74, 196, 333, 480, 638];
const EYES_MULTIPLIERS = ['x2', 'x3', 'x5', 'x7', 'x10'];

const freeSpinsMultiplierTextStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#412515',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 40,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#fff700',
  whiteSpace: 'normal',
};
const freeSpinsMultiplierTextInactiveStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#8d7c5e',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#fff700',
  whiteSpace: 'normal',
};
const freeSpinsMultiplierTextActiveStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: 'red',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#fff700',
  strokeThickness: 2,
  whiteSpace: 'normal',
};

class ProgressBarFreeSpins extends ViewContainer {
  private bar: Sprite;

  private backgroundAnimation: Spine;

  private progressBarEyeSpines: Spine[];

  private progressBarEyeTexts: TextField[];

  private eyesState: number[] = [];

  constructor() {
    super();
    this.visible = false;
    this.bar = this.initBar();
    this.backgroundAnimation = this.initBackgroundAnimation();
    this.progressBarEyeSpines = this.initProgressBarSpines();
    this.progressBarEyeTexts = this.initProgressBarTexts();
    this.resetEyeState();
    this.initSubscriptions();
    this.addChild(this.bar, this.backgroundAnimation, ...this.progressBarEyeSpines);
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.OPEN_MULTIPLIER_EYE, (id: number) => {
      AudioHowl.play({ type: ISongs.Progressbar_Fill, stopPrev: true });
      if (id < EYES_AMOUNT) {
        this.eyesState[id] = 1;
        eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION, EYES_MULTIPLIERS[id]);
        this.progressBarEyeTexts[id].setStyle(freeSpinsMultiplierTextStyle);
        this.progressBarEyeSpines[id].state.setAnimation(0, 'eye_activation', false);
        this.progressBarEyeSpines[id].state.addAnimation(0, 'eye_activation_idle', true, 0);
      } else {
        eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION, EYES_MULTIPLIERS[EYES_AMOUNT - 1]);
      }
    });
    eventManager.on(EventTypes.MULTIPLIER_EYE_WIN, () => {
      AudioHowl.play({ type: ISongs.Progressbar_Win });
      const id = this.eyesState.lastIndexOf(1);
      this.progressBarEyeTexts[id].setStyle(freeSpinsMultiplierTextActiveStyle);
      this.backgroundAnimation.state.setAnimation(0, 'pb_win_fs', false);
      this.progressBarEyeSpines[id].state.setAnimation(0, 'eye_win', false);
      this.progressBarEyeSpines[id].state.addAnimation(0, 'eye_win_idle', true, 0);
    });
    eventManager.on(EventTypes.CLOSE_ALL_MULTIPLIER_EYES, () => {
      AudioHowl.play({ type: ISongs.Progressbar_Off });
      const lastIndex = this.eyesState.lastIndexOf(1);
      for (let i = 0; i < EYES_AMOUNT; i++) {
        if (this.eyesState[i] !== 0) {
          this.progressBarEyeTexts[i].setStyle(freeSpinsMultiplierTextInactiveStyle);
          this.progressBarEyeSpines[i].state.setAnimation(0, i === lastIndex ? 'eye_off_3' : 'eye_off_2', false);
          this.progressBarEyeSpines[i].state.addAnimation(0, 'eye_close_static', true, 0);
        }
      }
      this.resetEyeState();
    });
    eventManager.on(EventTypes.IMMEDIATE_CLOSE_ALL_MULTIPLIER_EYES, () => {
      for (let i = 0; i < EYES_AMOUNT; i++) {
        this.progressBarEyeTexts[i].setStyle(freeSpinsMultiplierTextInactiveStyle);
        this.progressBarEyeSpines[i].state.setAnimation(0, 'eye_close_static', true);
      }
      this.resetEyeState();
    });
  }

  private initBar(): Sprite {
    const bar = new Sprite(Texture.from(ResourceTypes.progressBarFreeSpins));
    bar.x = 0;
    bar.y = -30;
    return bar;
  }

  private initBackgroundAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources.progress_bar_win.spineData!);
    animation.y = this.bar.y + this.bar.height / 2;
    animation.x = this.bar.x + this.bar.width / 2;
    return animation;
  }

  private initProgressBarSpines(): Spine[] {
    const animations = [];
    for (let i = 0; i < EYES_AMOUNT; i++) {
      const animation = new Spine(Loader.shared.resources.progress_bar.spineData!);
      animation.state.setAnimation(0, 'eye_close_static', true);
      animation.scale.set(EYES_SCALES[i]);
      animation.y = 55;
      animation.x = EYES_POSITIONS[i];
      animations.push(animation);
    }
    return animations;
  }

  private initProgressBarTexts(): TextField[] {
    const texts: TextField[] = [];
    for (let i = 0; i < EYES_AMOUNT; i++) {
      const text = new TextField(EYES_MULTIPLIERS[i], 65, 75, freeSpinsMultiplierTextInactiveStyle);
      text.text.anchor.set(0.5);
      this.progressBarEyeSpines[i].addChild(text.getText());
      texts.push(text);
    }
    return texts;
  }

  private resetEyeState(): void {
    for (let i = 0; i < EYES_AMOUNT; i++) {
      this.eyesState[i] = 0;
    }
  }

  protected resize(width: number, height: number): void {
    if (!isMobile) return;
    const isLandscape = width >= height;
    this.x = isLandscape ? 0 : 150;
    this.y = isLandscape ? 0 : -40;
    this.scale.set(isLandscape ? 1 : 1.5);
  }
}

export default ProgressBarFreeSpins;
