import { isMobile } from 'mobile-device-detect';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import { TextField } from './TextField';

export enum SpriteButtonState {
  DEFAULT,
  HOVER,
  PRESSED,
  DISABLED,
  ACTIVE,
}
export interface SpriteButtonProps {
  [SpriteButtonState.DEFAULT]: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  [SpriteButtonState.HOVER]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  [SpriteButtonState.PRESSED]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  [SpriteButtonState.DISABLED]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  onClick?: () => void;
  onHover?: () => void;
  onTouchStart?: () => void;
  textFiled?: TextField;
}
export class SpriteButton extends Sprite {
  protected state: SpriteButtonState = SpriteButtonState.DEFAULT;

  protected textures: { [key in SpriteButtonState]?: Texture };

  protected onClick: (() => void) | undefined;

  protected onHover: (() => void) | undefined;

  protected onTouchStart: (() => void) | undefined;

  protected textField?: TextField;

  protected textStyles?: { [key in SpriteButtonState]?: Partial<ITextStyle> };

  constructor(props: SpriteButtonProps) {
    super();
    this.anchor.set(0.5, 0.5);
    this.buttonMode = true;
    this.interactive = true;
    this.textures = {
      [SpriteButtonState.DEFAULT]: props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.HOVER]: props[SpriteButtonState.HOVER]
        ? props[SpriteButtonState.HOVER]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.PRESSED]: props[SpriteButtonState.PRESSED]
        ? props[SpriteButtonState.PRESSED]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.DISABLED]: props[SpriteButtonState.DISABLED]
        ? props[SpriteButtonState.DISABLED]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
    };
    this.textField = props.textFiled;
    this.textStyles = {
      [SpriteButtonState.DEFAULT]: props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.HOVER]: props[SpriteButtonState.HOVER]
        ? props[SpriteButtonState.HOVER]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.PRESSED]: props[SpriteButtonState.PRESSED]
        ? props[SpriteButtonState.PRESSED]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.DISABLED]: props[SpriteButtonState.DISABLED]
        ? props[SpriteButtonState.DISABLED]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
    };
    this.onHover = props.onHover;
    this.onClick = props.onClick;
    this.onTouchStart = props.onTouchStart;
    this.changeState(SpriteButtonState.DEFAULT);
    this.on('click', this.onClickCallback.bind(this));
    this.on('touchstart', this.onTouchStartCallback.bind(this));
    this.on('mouseover', this.onMouseOverCallback.bind(this));
    this.on('mouseout', this.onMouseOutCallback.bind(this));
    this.on('mousedown', this.onMouseDownCallback.bind(this));
    this.on('mouseup', this.onMouseUpCallback.bind(this));
    if (this.textField) {
      this.addChild(this.textField.getText());
      this.textField.setStyle(this.textStyles![SpriteButtonState.DEFAULT]!);
    }
  }

  protected onClickCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.DEFAULT);
    if (this.onClick) {
      this.onClick();
    }
  }

  protected onTouchStartCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.onTouchStart) {
      this.onTouchStart();
    }
  }

  protected onMouseOverCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.HOVER);
    if (this.onHover) {
      this.onHover();
    }
  }

  protected onMouseOutCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected onMouseDownCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.PRESSED);
  }

  protected onMouseUpCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(isMobile ? SpriteButtonState.DEFAULT : SpriteButtonState.HOVER);
  }

  public disable(): void {
    this.changeState(SpriteButtonState.DISABLED);
  }

  public enable(): void {
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected changeState(state: SpriteButtonState): void {
    this.state = state;
    this.texture = this.textures[state]!;
    if (this.textField && this.textStyles) {
      this.textField.setStyle(this.textStyles[state]!);
    }
  }
}
