import { isMobile } from 'mobile-device-detect';

import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { PopupTypes, SAFE_AREA_DESKTOP_BOTTOM_PADDING, SAFE_AREA_MOBILE_BOTTOM_PADDING, eventManager } from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 1;
    eventManager.on(EventTypes.OPEN_POPUP, (popupType: PopupTypes) => {
      if (popupType === PopupTypes.BUY_FEATURE || popupType === PopupTypes.BUY_FEATURE_CONFIRMATION) this.zIndex = 10;
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.zIndex = 1;
    });
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomSpace = bottomContainerHeight + bottomPadding;
    const isLandscape = width >= height;
    if (!isMobile && isLandscape) {
      newWidth = ((height - bottomSpace) * 4) / 3;
      newHeight = height - bottomSpace;
      return [newWidth - 50, newHeight - 50, bottomSpace + 50];
    }
    if (!isMobile) {
      newWidth = width;
      newHeight = width * 0.75 - bottomSpace;
      return [newWidth - 50, newHeight - 50, bottomSpace + 50];
    }
    if (isLandscape) {
      const additional = width > (height * 5) / 3 ? 0 : 50;
      newWidth = ((height - bottomSpace - additional) * 4) / 3;
      newHeight = height - bottomSpace - additional;

      return [newWidth, newHeight, bottomSpace + 100];
    }

    const nWidth = width;
    newWidth = nWidth;
    newHeight = nWidth * 0.75 - bottomSpace + 50;

    return [newWidth, newHeight, bottomSpace + 50];
  }

  protected resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    const isLandscape = width >= height;
    const x = (width - newWidth) / 2;
    const y = isLandscape
      ? Math.max((height - newHeight - bottomSpace / 2) * 0.5, 0)
      : Math.max((height - newHeight - bottomSpace / 2) * 0.35, 0);
    this.position.set(x, y);
    eventManager.emit(
      EventTypes.RESIZE_GAME_CONTAINER,
      newWidth,
      newHeight,
      Math.max((width - newWidth) / 2, 0),
      Math.max((height - newHeight - bottomSpace) / 2, 0),
    );
  }
}
export default SafeArea;
