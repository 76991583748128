import { TextStyle } from 'pixi.js';

const font = 'Arial';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#9f6518', '#ffeb19', '#FBC41C', '#F5FF3C', '#f08418'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
};

export const buyFeatureTitleStyle = new TextStyle({
  fontSize: 65,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '500',
  fill: 'white',
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 1,
  stroke: 'gray',
  strokeThickness: 1,
  lineJoin: 'round',
});

export const buyFeatureTitleStyleB = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 75,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#737373',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const buyFeatureTitleStyleC = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#737373',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostTextStyle = new TextStyle({
  fontSize: 70,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  fill: ['#0088ff', '#0000ff', '#000088'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalCostTextAmountStyle = new TextStyle({
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'normal',
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalCostBaseTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 45,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#737373',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostRageTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: '#ffa044',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 45,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#525252',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const titleBaseTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#737373',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const amountTextStyle = new TextStyle({
  fontSize: 43,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: 'white',
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 500,
  breakWords: true,
});

export const betValueStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#696969',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const okButtonPressedTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowDistance: 0,
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 39,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#7a7a7a',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});
export const okButtonHoverTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#ffea00',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 39,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: 'maroon',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const okButtonDisabledTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowDistance: 0,
  fill: '#575757',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 39,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#a3a3a3',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const okButtonDefaultTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 39,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: 'maroon',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const rageTitleConfirmColorTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: '#ffce8a',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 80,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#750000',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrap: true,
  wordWrapWidth: 460,
});

export const rageTitleBoldTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#292929',
  dropShadowDistance: 0,
  fill: '#ff5900',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 90,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: 'red',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const rageTitleColorTextStyle = new TextStyle({
  fill: '#ffa15f',
  fillGradientType: 1,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  fontWeight: 'bold',
  miterLimit: 14,
  stroke: 'red',
  strokeThickness: 10,
});

export const rageButtonActiveTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#9e2f00',
  dropShadowDistance: 0,
  fillGradientType: 1,
  fontFamily: 'Verdana',
  fontSize: 27,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#858585',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const rageButtonPressedTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fillGradientType: 1,
  fontFamily: 'Arial',
  fontSize: 27,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const rageButtonHoverTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#9e2f00',
  dropShadowDistance: 0,
  fill: '#f60',
  fillGradientType: 1,
  fontFamily: 'Arial',
  fontSize: 27,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: 'maroon',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const rageButtonDisabledTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAlpha: 0.6,
  dropShadowAngle: -1.6,
  dropShadowBlur: 9,
  dropShadowDistance: 0,
  fill: '#303030',
  fillGradientType: 1,
  fontFamily: 'Arial',
  fontSize: 27,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#858585',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});
export const rageButtonDefaultTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#9e2f00',
  dropShadowDistance: 0,
  fillGradientType: 1,
  fontFamily: 'Arial',
  fontSize: 27,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  stroke: '#858585',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const buyFeaturePressedTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAlpha: 0.5,
  dropShadowAngle: -0.6,
  dropShadowBlur: 7,
  dropShadowColor: '#231f1f',
  dropShadowDistance: 0,
  fillGradientStops: [0.3],
  fontFamily: 'Veranda',
  fontSize: 27,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 13,
  padding: 13,
  stroke: '#333',
  strokeThickness: 2,
  whiteSpace: 'normal',
  wordWrapWidth: 390,
});
export const totalCostActiveTextStyle = new TextStyle({
  fontSize: 38,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});

export const totalCostDisableTextStyle = new TextStyle({
  fontSize: 34,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: ['#685322', '#a08b54', '#c8b279', '#ab9363', '#a6925c'],
  fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  dropShadowDistance: 2,
  dropShadow: true,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});
