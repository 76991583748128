import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class Init extends State {
  public name: States = States.INIT;

  public nodes: Map<States, State> = new Map();

  public static the = new Init();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterInitState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitInitState(nextState);
  }
}
