import _ from 'lodash';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { setBetResult, setGameHistory, setSlotConfig } from '../../gql/cache';
import { getBetResult, saveReelPosition } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class Idle extends State {
  public name: States = States.IDLE;

  public nodes: Map<States, State> = new Map();

  public static the = new Idle();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterIdleState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitIdleState(nextState);
  }
}
