export * from './types';
export enum States {
  INIT,
  INTRO,
  BROKEN_GAME,
  IDLE,
  SPIN,
  BEFORE_WIN,
  WIN_PRESENTATION,
  AFTER_WIN,
  JINGLE,
  TRANSITION,
}
