import { EnterProps, GameMode, ISettledBet } from '../../global';
import { States } from '../config';

export abstract class Controller {
  public abstract gameMode: GameMode;

  public enterInitState(prevState: States): void {}

  public exitInitState(nextState: States): void {}

  public enterBrokenGameState(prevState: States): void {}

  public exitBrokenGameState(nextState: States): void {}

  public enterIntroState(prevState: States): void {}

  public exitIntroState(nextState: States): void {}

  public enterIdleState(prevState: States): void {}

  public exitIdleState(nextState: States): void {}

  public enterSpinState(prevState: States): void {}

  public exitSpinState(nextState: States): void {}

  public enterBeforeWinState(prevState: States): void {}

  public exitBeforeWinState(nextState: States): void {}

  public enterWinPresentationState(prevState: States): void {}

  public exitWinPresentationState(nextState: States): void {}

  public enterAfterWinState(prevState: States): void {}

  public exitAfterWinState(nextState: States): void {}

  public enterJingleState(prevState: States): void {}

  public exitJingleState(nextState: States): void {}

  public enterTransitionState(prevState: States): void {}

  public exitTransitionState(nextState: States): void {}

  public enterController(prevGameMode: GameMode | null, props?: EnterProps): void {}

  public exitController(nextGameMode: GameMode): void {}

  public setResult(result: ISettledBet): void {}
}
