import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, IBonus } from '../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { formatNumber, getBonusIdByFeature, normalizeCoins, showCurrency } from '../../utils';
import {
  betValueStyle,
  okButtonDefaultTextStyle,
  okButtonDisabledTextStyle,
  okButtonHoverTextStyle,
  okButtonPressedTextStyle,
  rageButtonActiveTextStyle,
  rageButtonDefaultTextStyle,
  rageButtonDisabledTextStyle,
  rageButtonHoverTextStyle,
  rageButtonPressedTextStyle,
  rageTitleBoldTextStyle,
  rageTitleColorTextStyle,
  titleBaseTextStyle,
  totalCostBaseTextStyle,
  totalCostRageTextStyle,
} from '../buyFeature/textStyles';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import {
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_HEIGHT,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
  FEATURE_POPUP_WIDTH,
  MOBILE_FEATURE_POPUP_HEIGHT,
  MOBILE_FEATURE_POPUP_POSITION_X,
  MOBILE_FEATURE_POPUP_POSITION_Y,
  MOBILE_FEATURE_POPUP_TITLE_POSITION_X,
  MOBILE_FEATURE_POPUP_WIDTH,
  PopupTypes,
  eventManager,
} from '../config';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopup extends Popup {
  private popupBg: Sprite;

  private baseModeBtn: FeatureButton;

  private rageModeBtn: FeatureButton;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private amountText: TextField;

  private totalCostText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private totalBetBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: TextField;

  private totalCostValue: TextField;

  private isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState = FeatureState.BASE;

  private labelText: TextField;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.x = 0;
    this.y = 0;
    this.visible = false;
    this.coinMultiplier = setSlotConfig().lineSets[0].coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.totalCostText = this.initTotalCostText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.totalBetBackplate = this.initTotalBetBackplate();
    this.betValue = this.initBetValue();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.baseModeBtn = this.initBaseModeBtn();
    this.rageModeBtn = this.initRageModeBtn();
    this.okBtn = this.initOkBtn();
    this.labelText = this.initLabelText();
    this.init();
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      PopupController.the.closeCurrentPopup();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public show(): void {
    super.show();
    this.closeAllAnimationsInSlot();
    this.visible = true;
  }

  public close(): void {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.betAmountBackplate);
    this.addChild(this.totalBetBackplate);
    this.addChild(this.titleText.getText());
    this.addChild(this.amountText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.betValue.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.baseModeBtn);
    this.addChild(this.rageModeBtn);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.width = isMobile ? MOBILE_FEATURE_POPUP_WIDTH : FEATURE_POPUP_WIDTH;
    popupBg.height = isMobile ? MOBILE_FEATURE_POPUP_HEIGHT : FEATURE_POPUP_HEIGHT;
    popupBg.x = isMobile ? MOBILE_FEATURE_POPUP_POSITION_X : FEATURE_POPUP_POSITION_X;
    popupBg.y = isMobile ? -70 : FEATURE_POPUP_POSITION_Y;

    return popupBg;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureTitleBase', { spinsNumber: 10 }),
      1100,
      100,
      {},
      true,
      {
        default: titleBaseTextStyle as Partial<ITextStyle>,
        bold: rageTitleBoldTextStyle as Partial<ITextStyle>,
        color: rageTitleColorTextStyle as Partial<ITextStyle>,
      },
    );
    title.text.y = 310;
    title.text.x = isMobile ? MOBILE_FEATURE_POPUP_TITLE_POSITION_X : 650;
    title.text.anchor.set(0.5, 0.5);
    title.text.resolution = 1;

    return title;
  }

  private initAmountText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyFeatureBetPerGame'),
      400,
      100,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = 575;
    amountText.text.x = 650;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initTotalCostText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = 390;
    amountText.text.x = 650;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.y = 680;
    minusBtn.x = 350;
    minusBtn.height = 95;
    minusBtn.width = 85;
    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = 680;
    plusBtn.x = 950;
    plusBtn.height = 95;
    plusBtn.width = 85;

    return plusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.y = 680;
    input.x = 650;
    input.width = 500;
    input.height = 90;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initTotalBetBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.y = 500;
    input.x = 650;
    input.width = 500;
    input.height = 90;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber(this.currency, normalizeCoins(this.getBetValue()), showCurrency(this.currency))}`,
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 652;
    betValue.text.x = 650;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(this.featureState),
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 470;
    betValue.text.x = 650;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnDisabled),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.width = 55;
    cancelBtn.height = 55;
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y - 750;
    cancelBtn.x = isMobile ? 1180 : FEATURE_POPUP_CANCEL_BTN_POSITION_X + 920;
    return cancelBtn;
  }

  private onCancel() {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    PopupController.the.closeCurrentPopup();
  }

  public hide(): void {
    super.hide();
    this.visible = false;
  }

  private initBaseModeBtn(): FeatureButton {
    const baseBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.freeSpinsPressed),
        textStyle: rageButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.freeSpinsDefault),
        textStyle: rageButtonDefaultTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.freeSpinsHover),
        textStyle: rageButtonHoverTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.freeSpinsPressed),
        textStyle: rageButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.freeSpinsDisabled),
        textStyle: rageButtonDisabledTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleBaseBtnClick.bind(this),
      onTouchStart: this.handleBaseBtnClick.bind(this),
      isActive: true,
      textFiled: this.initFreeSpinsButtonText(),
    });
    baseBtn.y = 170;
    baseBtn.x = 400;
    baseBtn.width = 325;
    baseBtn.height = 125;

    return baseBtn;
  }

  private initRageModeBtn(): FeatureButton {
    const rageBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.freeSpinsPressed),
        textStyle: rageButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.freeSpinsDefault),
        textStyle: rageButtonDefaultTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.freeSpinsHover),
        textStyle: rageButtonHoverTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.freeSpinsPressed),
        textStyle: rageButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.freeSpinsDisabled),
        textStyle: rageButtonDisabledTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleRageBtnClick.bind(this),
      onTouchStart: this.handleRageBtnClick.bind(this),
      textFiled: this.initFreeSpinsRageModeButtonText(),
    });
    rageBtn.y = 170;
    rageBtn.x = 900;
    rageBtn.width = 325;
    rageBtn.height = 125;

    return rageBtn;
  }

  private handleBaseBtnClick(): void {
    this.changeFeatureState(FeatureState.BASE);
    this.popupBg.texture = Texture.from(ResourceTypes.buyFeaturePopupConfirm);
    this.titleText.setText(
      i18n.t<string>('buyFeatureTitleBase', {
        spinsNumber: 10,
      }),
    );
    this.totalCostText.setStyle(totalCostBaseTextStyle);
    this.amountText.setStyle(totalCostBaseTextStyle);
    this.rageModeBtn.turnOff();
    this.baseModeBtn.turnOn();
    this.updateBets();
  }

  private handleRageBtnClick(): void {
    this.changeFeatureState(FeatureState.RAGE);
    this.popupBg.texture = Texture.from(ResourceTypes.buyFeaturePopup);
    this.titleText.setText(
      i18n.t<string>('buyFeatureTitleRage', {
        spinsNumber: 25,
      }),
    );
    this.totalCostText.setStyle(totalCostRageTextStyle);
    this.amountText.setStyle(totalCostRageTextStyle);
    this.rageModeBtn.turnOn();
    this.baseModeBtn.turnOff();
    this.updateBets();
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyButtonDefault),
        textStyle: okButtonDefaultTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyButtonHover),
        textStyle: okButtonHoverTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyButtonPressed),
        textStyle: okButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyButtonDisabled),
        textStyle: okButtonDisabledTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
      textFiled: this.initOkButtonText(),
    });
    okBtn.y = 800;
    okBtn.x = 650;
    okBtn.width = 355;
    okBtn.height = 115;
    return okBtn;
  }

  private initLabelText(): TextField {
    const label = new TextField(i18n.t<string>('buyFeatureButtonBuy'), 500, 100, betValueStyle as Partial<ITextStyle>);
    label.text.anchor.set(0.5, 0.5);
    label.text.x = 650;
    label.text.y = 800;
    return label;
  }

  private initFreeSpinsButtonText(): TextField {
    const freeSpinsText = new TextField(
      i18n.t<string>('freeSpinsButtonText'),
      250,
      50,
      rageButtonActiveTextStyle as Partial<ITextStyle>,
    );
    freeSpinsText.text.anchor.set(0.5, 0.5);
    return freeSpinsText;
  }

  private initFreeSpinsRageModeButtonText(): TextField {
    const freeSpinsRageText = new TextField(
      i18n.t<string>('freeSpinsRageModeButtonText'),
      250,
      70,
      rageButtonActiveTextStyle as Partial<ITextStyle>,
    );
    freeSpinsRageText.text.anchor.set(0.5, 0.5);
    return freeSpinsRageText;
  }

  private initOkButtonText(): TextField {
    const okButtonText = new TextField(
      i18n.t<string>('buyFeatureButtonBuy'),
      500,
      70,
      rageButtonActiveTextStyle as Partial<ITextStyle>,
    );
    okButtonText.text.anchor.set(0.5, 0.5);
    return okButtonText;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }

  private updateBets(): void {
    this.betValue.text.text = `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue()),
      showCurrency(this.currency),
    )}`;
    this.totalCostValue.text.text = this.getTotalCost(this.featureState);
  }

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency(this.currency),
    )}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(this.featureState),
        coinAmount: this.betSettings.bets[this.betAmount],
        featureState: this.featureState,
      });
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.featureState));
    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };

  private closeAllAnimationsInSlot(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
