import { ViewContainer } from '../components/ViewContainer';
import { PopupProps } from '../d';

export abstract class Popup extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 2;
  }

  public hide(): void {}

  public show(props?: PopupProps): void {}
}
