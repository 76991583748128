import i18n from 'i18next';
import { ITextStyle, Sprite, Text, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const freeSpinsLeftTextStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  fill: 'white',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  lineJoin: 'round',
  stroke: 'black',
  strokeThickness: 10,
  whiteSpace: 'normal',
};
export class FreeSpinsLeft extends ViewContainer {
  private background: Sprite;

  private spins: TextField;

  constructor() {
    super();
    this.x = 650;
    this.y = 910;
    this.visible = false;
    this.background = this.initBackground();
    this.spins = this.initSpins();
    this.addChild(this.background, this.spins.getText());
    eventManager.addListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, this.updateSpins.bind(this));
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = false;
        break;
      case GameMode.FREE_SPINS:
        this.visible = true;
        break;
      case GameMode.RAGE_MODE:
        this.visible = true;
        break;
      default:
        this.visible = false;
        break;
    }
  }

  private updateSpins(current: number, total: number): void {
    this.spins.setText(`${i18n.t('freeSpinsTitle')} ${current}/${total}`);
    this.spins.setStyle({
      ...freeSpinsLeftTextStyle,
    });
  }

  private initBackground(): Sprite {
    const background = new Sprite(Texture.from(ResourceTypes.freeSpinsCounter));
    background.anchor.set(0.5);
    return background;
  }

  private initSpins(): TextField {
    const text = new TextField('', 500, 70, freeSpinsLeftTextStyle);
    text.text.y = -20;
    text.text.anchor.set(0.5);
    text.text.resolution = 1;
    return text;
  }
}
