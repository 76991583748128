import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setIsDuringBigWinLoop } from '../../gql/cache';
import { Logic } from '../../logic';
import { delayedAction } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { PopupTypes, eventManager } from '../config';
import { PopupProps } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

const textStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#00d5ff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 90,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#00bfff',
  whiteSpace: 'normal',
};
export class RageModePopup extends Popup {
  protected background: Sprite;

  protected spine: Spine;

  protected text: TextField;

  protected textContainer: ViewContainer;

  protected backgroundFadeInAnimation: Animation;

  protected backgroundFadeOutAnimation: Animation;

  protected zoomAnimation: Animation;

  private spineListener = {
    complete: () => {
      this.zoomAnimation.end();
      eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
      this.backgroundFadeOutAnimation.start();
      this.removeListener('click', this.bindedCallback);
      this.removeListener('touchstart', this.bindedCallback);
      this.visible = false;
      PopupController.the.openPopup(PopupTypes.RAGE_MODE_INFO);
    },
  };

  private callback = () => {
    this.spine.state.removeListener(this.spineListener);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.removeListener('click', this.bindedCallback);
    this.removeListener('touchstart', this.bindedCallback);
    this.textContainer.alpha = 1;
    this.visible = false;
    PopupController.the.openPopup(PopupTypes.RAGE_MODE_INFO);
  };

  private bindedCallback = this.callback.bind(this);

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.text = new TextField(i18n.t('freeSpinsActivated'), 440, 100, textStyle);
    this.textContainer = new ViewContainer();
    this.textContainer.position.set(0, -70);
    this.text.text.anchor.set(0.5, 0.5);
    this.background = this.initBackground();
    this.spine = this.initSpine();
    this.zoomAnimation = this.initZoomAnimation();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.addChild(this.background);
    this.addChild(this.spine);
    this.textContainer.addChild(this.text.getText());
    this.spine.addChild(this.textContainer);
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  protected initSpine(): Spine {
    const spine = new Spine(Loader.shared.resources.popups.spineData!);
    return spine;
  }

  private initZoomAnimation(): Animation {
    const zoom = new AnimationChain();
    const zoomIn = new AnimationGroup();
    const zoomOut = new AnimationGroup();
    const zoomOut2 = new AnimationGroup();
    const zoomIn2 = new AnimationGroup();
    zoomIn.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1,
        target: 1.2,
        duration: 500,
      }),
    );
    zoomIn.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1,
        target: 1.2,
        duration: 500,
      }),
    );
    zoomIn2.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1,
        target: 1.2,
        duration: 500,
      }),
    );
    zoomIn2.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1,
        target: 1.2,
        duration: 500,
      }),
    );
    zoomOut.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1.2,
        target: 1,
        duration: 500,
      }),
    );
    zoomOut.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1.2,
        target: 1,
        duration: 500,
      }),
    );
    zoomOut2.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1.2,
        target: 1,
        duration: 500,
      }),
    );
    zoomOut2.addAnimation(
      new Tween({
        object: this.textContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1.2,
        target: 1,
        duration: 500,
      }),
    );
    zoom.appendAnimation(zoomIn);
    zoom.appendAnimation(zoomOut);
    zoom.appendAnimation(zoomIn2);
    zoom.appendAnimation(zoomOut2);
    zoom.appendAnimation(
      new Tween({
        object: this.textContainer,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 1,
        target: 0,
        duration: 500,
      }),
    );
    return zoom;
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  protected resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    this.spine.y = isMobile && !isLandscape ? -50 : 0;
    this.position.set(width / 2, height / 2);
    const factor = (isMobile && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.spine.scale.set(factor);
    this.textContainer.scale.set(factor);
  }

  public show(props?: PopupProps): void {
    AudioHowl.play({ type: ISongs.RM_EntrancePopup });
    delayedAction(mappedAudioSprites[ISongs.RM_EntrancePopup].duration, () => {
      if (
        !(
          PopupController.the.currentPopup &&
          PopupController.the.currentPopup === PopupController.the.popups.get(PopupTypes.FREE_SPINS_END)
        ) &&
        Logic.the.controller.gameMode === GameMode.RAGE_MODE
      ) {
        AudioHowl.play({ type: ISongs.BGM_RM_Loop });
      }
      if (setIsDuringBigWinLoop()) {
        AudioHowl.fadeOut(0, ISongs.BGM_RM_Loop);
      }
    });
    this.textContainer.alpha = 1;
    const factor = this.spine.scale.x;
    this.removeChild(this.spine);
    this.spine = new Spine(Loader.shared.resources.popups.spineData!);
    this.spine.addChild(this.textContainer);
    this.spine.scale.set(factor);
    this.addChildAt(this.spine, 1);
    this.on('click', this.bindedCallback);
    this.on('touchstart', this.bindedCallback);
    this.visible = true;
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.zoomAnimation.start();
    this.backgroundFadeInAnimation.start();
    this.spine.state.addListener(this.spineListener);
    this.spine.state.setAnimation(10, 'rage_mode_pop_up', false);
  }
}
