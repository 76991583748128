import AudioHowl from '@phoenix7dev/play-music';
import { Container, Loader, Sprite, Texture } from 'pixi.js';
import { Spine } from 'pixi-spine';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { nextTick } from '../../utils';
import { eventManager } from '../config';
import Slot from '../reels/slot';

export class RandomWilds extends Container {
  private generalWilds: Spine;

  constructor() {
    super();
    const dummy = new Sprite(Texture.EMPTY);
    dummy.width = 1920;
    dummy.height = 1080;
    dummy.position.set(this.width / 2, this.height / 2);
    this.addChild(dummy);
    this.generalWilds = new Spine(
      Loader.shared.resources.generalRandomWilds.spineData!,
    );
    this.generalWilds.position.set(dummy.width / 2, dummy.height / 2);
    this.generalWilds.visible = false;
    this.addChild(this.generalWilds);
    this.zIndex = 2;

    eventManager.addListener(
      EventTypes.START_GENERAL_RANDOM_WILDS,
      this.startRandomWildsGeneralAnimation.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_SINGLE_RANDOM_WILD,
      this.startSingleRandomAnimation.bind(this),
    );
  }

  private startSingleRandomAnimation(
    slot: Slot,
    isLast: boolean,
    nextCascadeId: number,
  ): void {
    const spine = new Spine(Loader.shared.resources.meteorWild.spineData!);
    const { x, y } = slot.getGlobalPosition();
    spine.position.set(x, y);
    spine.state.addListener({
      event(entry, event) {
        if (event.data.name === 'put_symbol') {
          slot.changeSlot(SlotId.WL);
        }
        if (event.data.name === 'music') {
          AudioHowl.play({
            type: ISongs.WildLanding,
            stopPrev: true,
          });
        }
      },
      complete: () => {
        nextTick(() => {
          spine.destroy();
          if (isLast) {
            eventManager.emit(EventTypes.NEXT_CASCADE, nextCascadeId);
          }
        });
      },
    });
    const maxDelay = 2;
    const delay = isLast ? maxDelay : Math.random() * maxDelay;
    spine.state.addAnimation(0, 'meteor_explosion', false, delay);
    this.addChild(spine);
  }

  private startRandomWildsGeneralAnimation(): void {
    this.generalWilds.visible = true;
    AudioHowl.play({ type: ISongs.FeatureWilds });
    this.generalWilds.state.setAnimation(0, 'meteor_rain', false);
    this.generalWilds.state.addListener({
      complete: () => {
        this.generalWilds.visible = false;
      },
    });
  }
}
