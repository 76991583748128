import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const EYES_AMOUNT = 8;
const FREE_SPINS_AMOUNTS = ['', '', '', '5', '10', '25', '50', '100'];
const freeSpinsAmountTextStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#421e00',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 55,
  fontWeight: 'bold',
  miterLimit: 65,
  stroke: '#421e00',
  strokeThickness: 3,
  whiteSpace: 'normal',
};
const freeSpinsAmountTextInactiveStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: '#8f8261',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 48,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#fff700',
  whiteSpace: 'normal',
};
const freeSpinsAmountTextActiveStyle: Partial<ITextStyle> = {
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: 'red',
  dropShadowDistance: 0,
  fill: 'red',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 55,
  fontWeight: 'bold',
  miterLimit: 65,
  stroke: '#fff700',
  strokeThickness: 3,
  whiteSpace: 'normal',
};
class ProgressBar extends ViewContainer {
  private bar: Sprite = new Sprite(Texture.from(ResourceTypes.progressBarBase));

  private backgroundAnimation: Spine;

  private progressBarEyeSpines: Spine[];

  private progressBarEyeTexts: TextField[];

  private eyesState: number[] = [];

  constructor() {
    super();
    this.x = -25;
    this.y = -20;
    this.visible = true;
    this.progressBarEyeSpines = this.initProgressBarSpines();
    this.progressBarEyeTexts = this.initProgressBarTexts();
    this.backgroundAnimation = this.initBackgroundAnimation();
    this.resetEyeState();
    this.initSubscriptions();
    this.addChild(this.bar, this.backgroundAnimation, ...this.progressBarEyeSpines);
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.OPEN_EYE, (id: number) => {
      AudioHowl.play({ type: ISongs.Progressbar_Fill, stopPrev: true });
      this.eyesState[id] = 1;
      this.progressBarEyeTexts[id].setStyle(freeSpinsAmountTextStyle);
      const animationName = id < 3 ? 'eye_activation_1' : 'eye_activation';
      this.progressBarEyeSpines[id].state.setAnimation(0, animationName, false);
      const idleAnimationName = id < 3 ? 'eye_open_idle' : 'eye_activation_idle';
      this.progressBarEyeSpines[id].state.addAnimation(0, idleAnimationName, true, 0);
    });
    eventManager.on(EventTypes.WIN_EYE, () => {
      AudioHowl.play({ type: ISongs.Progressbar_Win });
      const id = this.eyesState.lastIndexOf(1);
      this.progressBarEyeTexts[id].setStyle(freeSpinsAmountTextActiveStyle);
      this.backgroundAnimation.state.setAnimation(0, 'pb_win_main', false);
      this.progressBarEyeSpines[id].state.setAnimation(0, 'eye_win', false);
      this.progressBarEyeSpines[id].state.addAnimation(0, 'eye_win_idle', true, 0);
    });
    eventManager.on(EventTypes.CLOSE_ALL_EYES, () => {
      AudioHowl.play({ type: ISongs.Progressbar_Off });
      for (let i = 0; i < EYES_AMOUNT; i++) {
        if (this.eyesState[i] !== 0) {
          this.progressBarEyeTexts[i].setStyle(freeSpinsAmountTextInactiveStyle);
          this.progressBarEyeSpines[i].state.setAnimation(0, 'eye_off', false);
          this.progressBarEyeSpines[i].state.addAnimation(0, 'eye_close_static', true, 0);
        }
      }
      this.resetEyeState();
    });
    eventManager.on(EventTypes.IMMEDIATE_CLOSE_EYES, () => {
      for (let i = 0; i < EYES_AMOUNT; i++) {
        this.progressBarEyeTexts[i].setStyle(freeSpinsAmountTextInactiveStyle);
        this.progressBarEyeSpines[i].state.setAnimation(0, 'eye_close_static', true);
      }
      this.resetEyeState();
    });
  }

  private resetEyeState(): void {
    for (let i = 0; i < EYES_AMOUNT; i++) {
      this.eyesState[i] = 0;
    }
  }

  private initProgressBarSpines(): Spine[] {
    const animations = [];
    for (let i = 0; i < EYES_AMOUNT; i++) {
      const animation = new Spine(Loader.shared.resources.progress_bar.spineData!);
      animation.state.setAnimation(0, 'eye_close_static', true);
      animation.y = 65;
      animation.x = 72 + 101 * i;
      animations.push(animation);
    }
    return animations;
  }

  private initProgressBarTexts(): TextField[] {
    const texts: TextField[] = [];
    for (let i = 0; i < EYES_AMOUNT; i++) {
      const textfiled = new TextField(FREE_SPINS_AMOUNTS[i], 65, 75, freeSpinsAmountTextInactiveStyle);
      textfiled.text.anchor.set(0.5);
      texts[i] = textfiled;
      this.progressBarEyeSpines[i].addChild(textfiled.getText());
    }
    return texts;
  }

  private initBackgroundAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources.progress_bar_win.spineData!);
    animation.y = this.bar.height / 2;
    animation.x = this.bar.width / 2;
    return animation;
  }

  protected resize(width: number, height: number): void {
    if (!isMobile) return;
    const isLandscape = width > height;
    this.x = isLandscape ? -20 : 10;
    this.y = isLandscape ? -20 : -80;
    this.scale.set(isLandscape ? 1 : 1.5);
  }
}

export default ProgressBar;
