import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private isLandscape = true;

  private baseBg = Texture.from(ResourceTypes.slotBg);

  private freeSpinsBg = Texture.from(ResourceTypes.slotBgFreeSpins);

  private rageModeBg = Texture.from(ResourceTypes.slotBgRageMode);

  private backgroundSpine = new Spine(Loader.shared.resources.background.spineData!);

  private bgSprite = new Sprite();

  constructor() {
    super();

    this.bgSprite.texture = this.baseBg;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.addChild(this.backgroundSpine);
    this.backgroundSpine.state.setAnimation(0, 'bg', true);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  protected onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        break;
    }
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  private handleBaseModeChanges(): void {
    this.bgSprite.texture = this.baseBg;
    const name = isMobile && !this.isLandscape ? 'mobile_bg' : 'bg';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  private handleFreeSpinsChanges(): void {
    this.bgSprite.texture = this.freeSpinsBg;
    const name = isMobile && !this.isLandscape ? 'mobile_bg_fs' : 'bg_fs';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  private handleRageModeChanges(): void {
    this.bgSprite.texture = this.rageModeBg;
    const name = isMobile && !this.isLandscape ? 'mobile_bg_rm' : 'bg_rm';
    this.backgroundSpine.state.setAnimation(0, name, true);
  }

  protected resize(width: number, height: number): void {
    this.isLandscape = width >= height;
    if (this.backgroundSpine.state.tracks[0]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { name } = this.backgroundSpine.state.tracks[0].animation;
      if (this.isLandscape && name.includes('mobile')) {
        this.backgroundSpine.state.setAnimation(0, name.replace('mobile_', ''), true);
      }
      if (!this.isLandscape && !name.includes('mobile')) {
        this.backgroundSpine.state.setAnimation(0, `mobile_${name}`, true);
      }
    }
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.backgroundSpine.y = isMobile && !this.isLandscape ? -62 : 0;
      this.backgroundSpine.scale.set(!isMobile || this.isLandscape ? 1 : height / 590);
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.backgroundSpine.y = isMobile && !this.isLandscape ? -62 : 0;
      this.backgroundSpine.scale.set(!isMobile || this.isLandscape ? 1 : width / 496);
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
