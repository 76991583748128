import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { setCoinValue, setSlotConfig, setUserBalance } from '../../gql/cache';
import { getBetAmountGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  // if (slug === ResourceTypes.cS1 || slug === ResourceTypes.cS2)
  //   return (
  //     countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) *
  //     multiplier
  //   );
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSets[0].coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  // return slug === ResourceTypes.cS2 || slug === ResourceTypes.cS1;
  return false;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = userBalance.balance;

  return (
    <div className={styles['paytable-list']}>
      {data.map(({ combos, slug }) => (
        <div key={slug} className={styles['paytable-list__item']}>
          <div className={styles.img}>
            {/* dynamic used keys */}
            {/* t(infoPayTable_wL) */}
            {/* t(infoPayTable_a) */}
            {/* t(infoPayTable_b) */}
            {/* t(infoPayTable_c) */}
            {/* t(infoPayTable_d) */}
            {/* t(infoPayTable_e) */}
            {/* t(infoPayTable_f) */}
            {/* t(infoPayTable_g) */}
            {/* t(infoPayTable_h) */}
            {/* t(infoPayTable_i) */}
            {/* t(infoPayTable_j) */}
            {hasTranslate(slug) && <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>}
            <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
          </div>
          <div>
            <table className={styles.content}>
              {combos.map((i) => (
                <tr key={i.pattern}>
                  <td className={styles.multiplier}>{i.pattern}</td>
                  <td>
                    {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                  </td>
                </tr>
              ))}
            </table>
            {slug === ResourceTypes.wL && <div className={styles.additional}>{t('infoPayTableWildText')}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaytableComponent;
