import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class Backdrop extends ViewContainer {
  private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));

  constructor(openOn: EventTypes, closeOn: EventTypes) {
    super();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0.75;
    this.backdrop.interactive = true;
    this.backdrop.x = -this.backdrop.width / 2;
    this.backdrop.y = -this.backdrop.height / 2;
    this.scale.set(4, 4);

    this.trackVisibility(openOn, closeOn);
  }

  public trackVisibility(openOn: EventTypes, closeOn: EventTypes): void {
    eventManager.on(openOn, () => {
      this.backdrop.visible = true;
    });
    eventManager.on(closeOn, () => {
      this.backdrop.visible = false;
    });
  }
}
export default Backdrop;
