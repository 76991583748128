import { Sprite, Texture } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { getFromMappedSymbol } from '../../utils';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH } from '../config';

class Slot extends Sprite {
  public id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId)));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.anchor.set(0.5, 0.5);
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}

export default Slot;
