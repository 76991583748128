/* eslint-disable import/no-dynamic-require */

/* eslint-disable @typescript-eslint/no-var-requires */

/* eslint-disable global-require */
import { Resources } from '@phoenix7dev/shared-components';

import resources from '../assets/resources.json';

export default new Resources(resources).init((r: string): string => {
  return require(`../assets/${r}`).default;
});
