import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = 'BGM_BG_Base_Loop',
  BGM_BG_Melo_Loop = 'BGM_BG_Melo_Loop',
  BGM_FS_Loop = 'BGM_FS_Loop',
  BGM_RM_Loop = 'BGM_RM_Loop',
  BigWin_End = 'BigWin_End',
  BigWin_Loop = 'BigWin_Loop',
  BuyButton = 'BuyButton',
  Cascade_Symbol_Explosion = 'Cascade_Symbol_Explosion',
  FS_EntrancePopup = 'FS_EntrancePopup',
  FS_ExitPopup = 'FS_ExitPopup',
  FeatureWilds = 'FeatureWilds',
  HighWin = 'HighWin',
  MediumWin = 'MediumWin',
  Multiplier_Popup = 'Multiplier_Popup',
  Progressbar_Fill = 'Progressbar_Fill',
  Progressbar_Off = 'Progressbar_Off',
  Progressbar_Win = 'Progressbar_Win',
  RM_EntrancePopup = 'RM_EntrancePopup',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  SmallWin = 'SmallWin',
  WildLanding = 'WildLanding',
  Win_Big = 'Win_Big',
  Win_Epic = 'Win_Epic',
  Win_Great = 'Win_Great',
  Win_Mega = 'Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Base_Loop]: [0, 58909.125, true],
  [ISongs.BGM_BG_Melo_Loop]: [60000, 58909.06250000001, true],
  [ISongs.BGM_FS_Loop]: [120000, 58434.81249999999, true],
  [ISongs.BGM_RM_Loop]: [180000, 59076.937499999985, true],
  [ISongs.BigWin_End]: [241000, 6620.687500000003],
  [ISongs.BigWin_Loop]: [249000, 39724.18749999997, true],
  [ISongs.BuyButton]: [290000, 1273.6249999999814],
  [ISongs.Cascade_Symbol_Explosion]: [293000, 1864.9374999999964],
  [ISongs.FS_EntrancePopup]: [296000, 6375],
  [ISongs.FS_ExitPopup]: [304000, 6193.5624999999845],
  [ISongs.FeatureWilds]: [312000, 2046.4375000000246],
  [ISongs.HighWin]: [316000, 3933.0625000000055],
  [ISongs.MediumWin]: [321000, 2867.1875],
  [ISongs.Multiplier_Popup]: [325000, 1710.6249999999932],
  [ISongs.Progressbar_Fill]: [328000, 1729.3124999999918],
  [ISongs.Progressbar_Off]: [331000, 2374.3124999999736],
  [ISongs.Progressbar_Win]: [335000, 3361.7499999999723],
  [ISongs.RM_EntrancePopup]: [340000, 10461.562500000015],
  [ISongs.SFX_SM_CountUp_End]: [352000, 933.437500000025],
  [ISongs.SFX_SM_CountUp_Loop]: [354000, 399.7499999999832],
  [ISongs.SFX_UI_AutoSpin]: [356000, 320.43750000002547],
  [ISongs.SFX_UI_BetChange]: [358000, 32.062499999995],
  [ISongs.SFX_UI_Close]: [360000, 200.68749999995816],
  [ISongs.SFX_UI_General]: [362000, 88.3125000000291],
  [ISongs.SFX_UI_Hover]: [364000, 151.74999999999272],
  [ISongs.SFX_UI_MaxBet]: [366000, 122.93750000003456],
  [ISongs.SFX_UI_MenuOpen]: [368000, 277.12499999995543],
  [ISongs.SFX_UI_SpinStart]: [370000, 249.56250000002456],
  [ISongs.SFX_UI_SpinStop]: [372000, 150.99999999995362],
  [ISongs.SFX_WIN_FeatureTrigger]: [374000, 879.8124999999573],
  [ISongs.SmallWin]: [377000, 2176.8124999999827],
  [ISongs.WildLanding]: [381000, 2764.9374999999736],
  [ISongs.Win_Big]: [385000, 2400.06249999999],
  [ISongs.Win_Mega]: [389000, 3900.06249999999],
  [ISongs.Win_Great]: [394000, 4050.062500000024],
  [ISongs.Win_Epic]: [400000, 1750.0625000000127],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.2,
  [ISongs.BGM_BG_Melo_Loop]: 0.2,
  [ISongs.BGM_FS_Loop]: 0.2,
  [ISongs.BGM_RM_Loop]: 0.2,
  [ISongs.BigWin_Loop]: 0.2,
  [ISongs.BigWin_End]: 0.2,
  [ISongs.BuyButton]: 0.2,
  [ISongs.Cascade_Symbol_Explosion]: 0.2,
  [ISongs.FS_EntrancePopup]: 0.2,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.FS_ExitPopup]: 0.3,
  [ISongs.FeatureWilds]: 0.3,
  [ISongs.HighWin]: 0.3,
  [ISongs.MediumWin]: 0.3,
  [ISongs.SmallWin]: 0.3,
  [ISongs.WildLanding]: 0.8,
  [ISongs.Multiplier_Popup]: 0.4,
  [ISongs.Progressbar_Fill]: 0.4,
  [ISongs.Progressbar_Off]: 0.4,
  [ISongs.Progressbar_Win]: 0.3,
  [ISongs.RM_EntrancePopup]: 0.3,
  [ISongs.Win_Big]: 0.3,
  [ISongs.Win_Mega]: 0.3,
  [ISongs.Win_Great]: 0.3,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.133,
  [ISongs.SFX_UI_SpinStop]: 0.2,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
