import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig, setUserBalance } from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { formatNumber } from '../../utils';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const userBalance = useReactiveVar(setUserBalance);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const betSettings = getBetsSetting();
  const { currency } = userBalance.balance;
  const minBet = formatNumber(currency, betSettings.minBetCount);
  const maxBet = formatNumber(currency, betSettings.maxBetCount);
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  const gameRules1 = [
    { key: 'infoGameRules1_1', text: i18n.t('infoGameRules1_1'), buyFeatureOnly: false },
    { key: 'infoGameRules1_2', text: i18n.t('infoGameRules1_2'), buyFeatureOnly: false },
    { key: 'infoGameRules1_3', text: i18n.t('infoGameRules1_3'), buyFeatureOnly: false },
  ];
  const gameRules2 = [
    { key: 'infoGameRules1_4', text: i18n.t('infoGameRules1_4'), buyFeatureOnly: false },
    { key: 'infoGameRules1_5', text: i18n.t('infoGameRules1_5'), buyFeatureOnly: false },
    { key: 'infoGameRules1_6', text: i18n.t('infoGameRules1_6'), buyFeatureOnly: false },
    { key: 'infoGameRules1_7', text: i18n.t('infoGameRules1_7'), buyFeatureOnly: false },
    { key: 'infoGameRules1_8', text: i18n.t('infoGameRules1_8'), buyFeatureOnly: false },
    { key: 'infoGameRules1_9', text: i18n.t('infoGameRules1_9'), buyFeatureOnly: false },
    { key: 'infoGameRules1_10', text: i18n.t('infoGameRules1_10'), buyFeatureOnly: false },
  ];
  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title_rule}>{t('infoGameRulesTitle')}</h1>
      <p className={`${styles.p} ${styles.left}`}>
        {gameRules1
          .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
          .map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} className="game-rule" />
          ))}
      </p>
      <p className={`${styles.p} ${styles.left}`}>
        {gameRules2
          .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
          .map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
          ))}
      </p>
      {/* <p className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules1_9', {
              minBet,
            }),
          }}
        />
      </p> */}
      <p className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules2', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules3', {
              maxBet,
            }),
          }}
        />
      </p>
      <p className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules4', {
              rtp: '96.38%',
            }),
          }}
        />
        {setSlotConfig().isBuyFeatureEnabled && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: t('infoGameRules5', {
                  rtp: '95.90%',
                }),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('infoGameRules6', {
                  rtp: '96.66%',
                }),
              }}
            />
          </>
        )}
        {/* {isBuyFeatureEnabled ? (
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '96.66%',
              }),
            }}
          />
        ) : (
          ''
        )} */}
      </p>
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRulesVersion', {
            version,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
