import { CallbackPriority, IAnimation } from './d';

class Animation implements IAnimation {
  public delay: number;

  public change: (() => void)[];

  public complete: { callback: () => void; priority: CallbackPriority }[];

  public skips: (() => void)[];

  public starts: (() => void)[];

  public easing: (n: number) => number;

  public duration: number;

  public startTime: number;

  public isLoop: boolean;

  public ended = false;

  public update: ((newValue: number) => void) | null;

  constructor(options: IAnimation) {
    this.delay = options.delay ?? 0;
    this.change = options.change ?? [];
    this.complete = options.complete ?? [];
    this.skips = options.skips ?? [];
    this.starts = options.starts ?? [];
    this.easing = options.easing ?? ((n) => n);
    this.duration = options.duration ?? 0;
    this.startTime = options.startTime ?? -1;
    this.isLoop = options.isLoop ?? false;
    this.update = options.update ?? null;
  }

  public end(): void {}

  public start(): void {
    this.onStart();
    this.startTime = Date.now();
  }

  public onStart(): void {
    this.starts.forEach((callback) => callback());
  }

  public onComplete(): void {
    if (!this.isLoop) this.ended = true;
    if (!this.complete.length) return;
    this.complete
      .filter((elem) => elem !== undefined)
      .sort((elem1, elem2) => elem2.priority - elem1.priority)
      .forEach((complete) => complete.callback());
    if (this.isLoop) this.start();
  }

  public onChange(): void {
    this.change.forEach((callback) => callback());
  }

  public onSkip(isLoop?: boolean): void {
    this.ended = true;
    this.skips.forEach((callback) => callback());
  }

  public addOnChange(callback: () => void): void {
    this.change.push(callback);
  }

  public addOnComplete(callback: () => void, priority?: CallbackPriority): void {
    this.complete.push({
      callback,
      priority: priority !== undefined ? priority : CallbackPriority.REGULAR,
    });
  }

  public removeOnComplete(callback: () => void): void {
    this.complete.splice(
      this.complete.findIndex((complete) => complete.callback === callback),
      1,
    );
  }

  public cleanUpOnComplete(): void {
    this.complete = [];
  }

  public addOnSkip(callback: () => void): void {
    this.skips.push(callback);
  }

  public addOnStart(callback: () => void): void {
    this.starts.push(callback);
  }

  public skip(isLoop?: boolean): void {
    this.isLoop = false;
    this.duration = 0;
    this.onSkip();
  }
}

export default Animation;
