import AudioHowl from '@phoenix7dev/play-music';
import { Play } from '@phoenix7dev/play-music/dist/d';
import * as PIXI from 'pixi.js';
import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { ISongs, SlotId } from '../config';
import {
  baseReelSetIds,
  bonusesId,
  buyFeatureBonusesId,
  Cascade,
  EventTypes,
  FeatureState,
  GameMode,
  ISettledBet,
  ReelSet,
} from '../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setIsDuringBigWinLoop,
  setIsSuspended,
  setSlotConfig,
} from '../gql/cache';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import Tween from '../slotMachine/animations/tween';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  eventManager,
  GREAT_WIN_AMOUNT_LIMIT,
  HIGH_PAYING_SYMBOLS,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';
import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap = (
  fn: CallableFunction,
  ...partOne: Helper.RestArguments
) => (...partTwo: Helper.RestArguments): unknown => {
  const args: Helper.RestArguments = [...partOne, ...partTwo];
  if (args.length) {
    return fn(...args);
  }
  return fn();
};

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const normalize = (coord: number, layout: string[]) => {
  return coord < 0
    ? layout.length - (Math.abs(coord) % layout.length)
    : coord % layout.length;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState];
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS || mode === GameMode.RAGE_MODE;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};
export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (
    multiplier >= DOUBLE_WIN_AMOUNT_LIMIT &&
    multiplier < BASE_WIN_AMOUNT_LIMIT
  ) {
    return WinStages.BaseWin;
  }
  if (
    multiplier >= BASE_WIN_AMOUNT_LIMIT &&
    multiplier < BIG_WIN_AMOUNT_LIMIT
  ) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT)
    return WinStages.MegaWin;
  if (
    multiplier >= MEGA_WIN_AMOUNT_LIMIT &&
    multiplier < GREAT_WIN_AMOUNT_LIMIT
  )
    return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return baseReelSetIds.includes(reelSetId);
};
export const isBuyFeatureBonus = (bonusId: string): boolean => {
  return bonusesId[GameMode.BUY_FEATURE] === bonusId;
};
export const isRageModeBonus = (bonusId: string): boolean => {
  return bonusesId[GameMode.BUY_FEATURE] === bonusId;
};
export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME || mode === GameMode.FREE_ROUND_BONUS;
};
export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * 50) / 100;
};

export const normalizePosition = (length: number, position: number): number => {
  return (length + position) % length;
};
export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getCascadeColumns = ({
  reelPositions,
  layout,
  cascades,
}: {
  reelPositions: number[];
  layout: SlotId[][];
  cascades: Cascade[];
}): Array<Array<Icon>> => {
  const { icons } = setSlotConfig();
  const moreSlots: SlotId[][] = [[], [], [], [], []];
  cascades
    .filter((cascade) => !cascade.isRandomWilds)
    .forEach((cascade) => {
      cascade.cascadeFall.reverse().forEach((cascadeFalls, index) => {
        cascadeFalls.forEach((slot, idx) => {
          if (slot !== '') {
            moreSlots[idx].push(slot);
          }
        });
      });
    });
  const res = reelPositions.map((position, index) => {
    const start = position + 3;
    const slots: Icon[] = [];
    for (let i = 0; i < 4; i++) {
      const position = normalizePosition(layout[index].length, start - i);
      slots.push(icons.find((icon) => icon.id === layout[index][position])!);
    }
    for (let i = 0; i < moreSlots[index].length; i++) {
      slots.push(icons.find((icon) => icon.id === moreSlots[index][i])!);
    }

    return slots.reverse();
  });
  return res;
};
export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const cols = 5;
  const rows = 4;

  return [...Array(cols * rows)].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column];

    const initialCoord = reelPositions[column];
    const coord = initialCoord + row;

    return (
      icons.find(
        (icon) => icon.id === reelSet.layout[column][normalize(coord, layout)],
      ) || icons[0]
    );
  });

  const spinResult: Icon[] = [];
  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 4; j++) {
      spinResult.push(icons[Math.floor(Math.random() * icons.length)]);
    }
  }
  return spinResult;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
}): boolean => {
  if (
    (isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) && isFreeSpinsWin
  ) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (
  options: Partial<DropShadowFilterOptions>,
): PIXI.Filter => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};
export const isMultipliersAvailable = (mode: GameMode): boolean => {
  return false;
};
export const delayedAction = (
  delay: number,
  completeCallback: () => void,
  startCallback?: () => void,
): void => {
  const delayAnim = Tween.createDelayAnimation(delay);
  if (startCallback) {
    delayAnim.addOnStart(startCallback);
  }
  delayAnim.addOnComplete(completeCallback);
  delayAnim.start();
};

export const isHighPaySymbols = (
  spinResult: Icon[],
  positions: number[],
): boolean => {
  return positions.some((position) => {
    return HIGH_PAYING_SYMBOLS.includes(spinResult[position].id);
  });
};

export const isPlayableSlot = (index: number, mode: GameMode): boolean => {
  return true;
};

export const isRageModeWin = (result: ISettledBet): boolean => {
  return result.bet.data.features.multiplier === 10;
};

export const getPlayListOnBroken = (isDuringBigWinLoop: boolean): Play[] => {
  if (isDuringBigWinLoop) {
    return [
      { type: ISongs.BigWin_Loop, volume: 0 },
      { type: ISongs.BigWin_Loop },
    ];
  }
  return [{ type: ISongs.BigWin_Loop }];
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Melo_Loop;
    case GameMode.FREE_SPINS:
      return ISongs.BGM_FS_Loop;
    case GameMode.RAGE_MODE:
      return ISongs.BGM_RM_Loop;
    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};
export const getBGMSoundChangeRestriction = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Base_Loop;
    case GameMode.FREE_SPINS:
      return ISongs.BGM_FS_Loop;
    case GameMode.RAGE_MODE:
      return ISongs.BGM_RM_Loop;
    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  if (setBrokenGame()) {
    setIsSuspended(false);
    AudioHowl.unSuspend();
    AudioHowl.changeRestriction(false, []);
    eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    const listToPlay: Array<Play> = [];
    if (setIsDuringBigWinLoop()) {
      listToPlay.push({ type: ISongs.BigWin_Loop });
    }
    switch (mode) {
      case GameMode.BASE_GAME:
        listToPlay.push({ type: ISongs.BGM_BG_Base_Loop });
        break;
      case GameMode.FREE_SPINS:
        listToPlay.push({ type: ISongs.BGM_FS_Loop });
        break;
      case GameMode.RAGE_MODE:
        listToPlay.push({ type: ISongs.BGM_RM_Loop });
        break;
      default:
        listToPlay.push({ type: ISongs.BGM_BG_Base_Loop });
        break;
    }
    AudioHowl.changeRestriction(false, listToPlay);
  }
};
export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.WL:
      return 11;
    case SlotId.A:
      return 10;
    case SlotId.B:
      return 9;
    case SlotId.C:
      return 8;
    case SlotId.D:
      return 7;
    case SlotId.E:
      return 6;
    case SlotId.F:
      return 5;
    case SlotId.G:
      return 4;
    case SlotId.H:
      return 3;
    case SlotId.I:
      return 2;
    case SlotId.J:
      return 1;
    default:
      return 0;
  }
};

export const cascadeEase = (x: number): number => {
  const c1 = 1.70158;
  const c2 = c1 * 1;

  return x < 0.5
    ? // eslint-disable-next-line no-restricted-properties
      (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
    : // eslint-disable-next-line no-restricted-properties
      Math.pow(x, 2);
  // (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
};
