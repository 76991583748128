import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, GameMode } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql/cache';
import { Logic } from '../../logic';
import { BaseController } from '../../logic/controllers/BaseController';
import { ResourceTypes } from '../../resources.d';
import { getBonusIdByFeature } from '../../utils';
import {
  buyFeatureTitleStyleB,
  buyFeatureTitleStyleC,
  rageTitleBoldTextStyle,
  rageTitleConfirmColorTextStyle,
} from '../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import {
  FEATURE_POPUP_HEIGHT,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
  FEATURE_POPUP_WIDTH,
  MOBILE_FEATURE_POPUP_HEIGHT,
  MOBILE_FEATURE_POPUP_POSITION_X,
  MOBILE_FEATURE_POPUP_WIDTH,
  PopupTypes,
  eventManager,
} from '../config';
import { BuyFeatureConfirmProps } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg: Sprite = new Sprite(Texture.WHITE);

  private coinAmount: number;

  private featureState: FeatureState = FeatureState.BASE;

  private titleText: TextField;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private betAmountBackplate: Sprite;

  private okBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.x = 0;
    this.y = 75;
    this.visible = false;
    this.coinAmount = 1;
    this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose();
    });
  }

  public show(props: BuyFeatureConfirmProps): void {
    super.show();
    const { featureState, coinAmount, totalCost } = props;
    this.popupBg.texture = Texture.from(
      featureState === FeatureState.BASE ? ResourceTypes.buyFeaturePopupConfirm : ResourceTypes.buyFeaturePopup,
    );
    this.titleText.setText(
      i18n.t<string>(featureState === FeatureState.BASE ? 'buyFeatureConfirmTitleBase' : 'buyFeatureConfirmTitleRage'),
    );
    this.totalCostText.setStyle(
      featureState === FeatureState.BASE ? buyFeatureTitleStyleB : rageTitleConfirmColorTextStyle,
    );
    this.totalCostValue.setStyle(featureState === FeatureState.BASE ? buyFeatureTitleStyleB : buyFeatureTitleStyleB);
    this.featureState = featureState;
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.betAmountBackplate);
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): void {
    this.popupBg.width = isMobile ? MOBILE_FEATURE_POPUP_WIDTH : FEATURE_POPUP_WIDTH;
    this.popupBg.height = isMobile ? MOBILE_FEATURE_POPUP_HEIGHT : FEATURE_POPUP_HEIGHT;
    this.popupBg.x = isMobile ? MOBILE_FEATURE_POPUP_POSITION_X : FEATURE_POPUP_POSITION_X;
    this.popupBg.y = isMobile ? -143 : FEATURE_POPUP_POSITION_Y - 75;
  }

  private initTitle(): TextField {
    const title = new TextField(i18n.t<string>('buyFeatureConfirmTitleBase'), 1100, 200, {}, true, {
      default: buyFeatureTitleStyleB as Partial<ITextStyle>,
      bold: rageTitleBoldTextStyle as Partial<ITextStyle>,
      color: rageTitleConfirmColorTextStyle as Partial<ITextStyle>,
    });
    title.text.y = 195;
    title.text.x = 650;
    title.text.anchor.set(0.5, 0.5);
    title.text.resolution = 1;
    return title;
  }

  private initTotalCostText(): TextField {
    const titleCost = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      1100,
      100,
      buyFeatureTitleStyleB as Partial<ITextStyle>,
    );
    titleCost.text.y = 420;
    titleCost.text.x = 650;

    titleCost.text.anchor.set(0.5, 0.5);

    return titleCost;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField('0', 500, 100, buyFeatureTitleStyleC as Partial<ITextStyle>);
    totalCostValue.text.y = 560;
    totalCostValue.text.x = 650;
    totalCostValue.text.anchor.set(0.5, 0.5);
    return totalCostValue;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.y = 560;
    input.x = 650;
    input.width = 650;
    input.height = 120;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        this.handleClose();
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      },
    });
    cancelBtn.anchor.set(0.5, 0.5);
    cancelBtn.y = 720;
    cancelBtn.x = 410;
    cancelBtn.width = 175;
    cancelBtn.height = 130;
    return cancelBtn;
  }

  private initOkBtn(): Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioHowl.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioHowl.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
    });

    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = 720;
    okBtn.x = 880;
    okBtn.width = 175;
    okBtn.height = 130;
    return okBtn;
  }

  private startBuyFeature(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
    setIsBuyFeaturePurchased(true);
  }

  public handleClose(closeBuyFeature?: boolean): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    if (!setIsBuyFeaturePurchased()) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
    } else {
      // workaround when buy feature during offline mode
      Logic.the.controller = BaseController.the;
      Logic.the.controller.enterController(GameMode.BASE_GAME);
      PopupController.the.closeCurrentPopup();
    }
  }
}

export default BuyFeaturePopupConfirm;
